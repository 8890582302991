.root {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 28rem;
  padding: 1.5rem;
  padding-top: 8rem;
  .content {
    margin: auto;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    &.snapToBottom {
      position: fixed;
      bottom: 5%;
    }
    .inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 100%;
    }
  }
}

.root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 2rem;
  .avatar {
    margin-bottom: 0.5rem;
    height: 3rem;
    width: 3rem;
  }
  .totalImpact {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
  }
  .name {
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.root {
  .tenant {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 1.5rem;
    .tenantLogo {
      height: 2rem;
    }
    .tenantSelector {
      margin-left: 1rem;
    }
  }
  .stats {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    .stat {
      margin-right: 2rem;
      display: inline-block;
      text-align: left;
    }
    .statValue {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }
    .statLabel {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .biography {
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .socialLinks {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.25rem;
    margin-bottom: 1.5rem;
    a {
      --tw-text-opacity: 1;
      color: rgba(107, 114, 128, var(--tw-text-opacity));
      text-decoration: none;
      span {
        text-decoration: underline;
      }
    }
  }
  .outlineBtn {
    width: 100%;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms;
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      --tw-border-opacity: 1;
      border-color: rgba(59, 130, 246, var(--tw-border-opacity));
    }
    &:active {
      --tw-border-opacity: 1;
      border-color: rgba(30, 64, 175, var(--tw-border-opacity));
    }
  }
  .impactItems {
    display: grid;
    grid-auto-columns: -webkit-min-content;
    grid-auto-columns: min-content;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.75rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}

.root {
  img {
    height: 2.5rem;
    width: auto;
    margin: auto;
    border-radius: 9999px;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
}

.root {
  h1 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
  .outlineBtn {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 0.5rem;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.75rem;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 150ms;
            transition-duration: 150ms;
    -webkit-transition-duration: 200ms;
            transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      --tw-border-opacity: 1;
      border-color: rgba(59, 130, 246, var(--tw-border-opacity));
    }
    &:active {
      --tw-border-opacity: 1;
      border-color: rgba(30, 64, 175, var(--tw-border-opacity));
    }
  }
  .connect {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    height: 3.125rem;
    svg {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      width: 1.1em;
      path {
        fill: #0a2540;
      }
    }
  }
  div.connect:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
}
